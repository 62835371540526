import React from 'react';

const Tag =(props) =>{

    const bgColor = props.bgColor? props.bgColor: 'orange';
    const textColor = props.textColor? props.textColor: 'white';
    
    return(
        <div style={{
                display:'inline-block',
                fontSize:props.fontSize? props.fontSize : '0.5em',
                backgroundColor:bgColor,
                color: textColor,
                padding: props.label? '0 10px': null,
                margin: '0 10px',
                fontWeight:'400',
                borderRadius: props.label? '4px': '10px',
                width: props.label? '': '10px',
                height: props.label? '': '10px'
            }}>
            {props.label}
        </div>
    )
}

export default Tag;