import { ComingSoon } from "../../pages/ComingSoon";
import ReturnPolicy from "../../pages/ReturnPolicy"; 
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsOfUse from "../../pages/TermsOfUse";
import { Page404 } from "../../pages/Page404";

import { ShopBanner, HelpBanner } from "../Header/Banner";

export const BannerMatcher =(path) =>{
    switch(path){
        // HomePage
        case '/':
        case '/about':
        case '/quality-checks/crafted-products': return ShopBanner;

        case '/return-policy': 
        case '/privacy-policy': 
        case '/terms-of-use': 
        case '/security':
        case '/help/payments':
        case '/help/faq':
        case '/help/refunds-cancellation': return HelpBanner;

        case '/contact':
        default: return null;
    }
    return ;
}