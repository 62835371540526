import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { title, webTitle, website, complaintsEmail, unsubscribeFromPromoEmailsURL, grievanceOfficerName, grievanceOfficerEmail, supportTeamEmail, onlineStoreWebsite, blogWebsite, allWebsites } from './Constants';
import './PrivacyPolicy.scss';
import { Link } from '../components/extraFunctions/Link';
import { WasThisHelpful } from '../components/survey/SurveyQuestions';
import {ReactComponent as Security} from '../assets/svg/security.svg';

const SecurityInfo =() =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    // useEffect(()=>{
    //     window.scrollTo({ top:0, behavior:'smooth'})
    // })
    return(
        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px',textAlign:'justify', fontSize:'0.9rem'}}>
                <Helmet>
                    <title>Security - {webTitle}</title>
                </Helmet>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <h1>Security</h1>
                    <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                        {/* Date updated Privacy Policy */}
                        <span style={{fontWeight:'600'}}>28 October, 2023</span>
                    </span>
                </div>
                <div style={{height:'50px'}}></div>
                <div className='centered-banner full-width' style={{background:'#c6e7cc'}}>
                    <div style={{position:'relative', width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{width:'100px', height:'100px'}}>
                            <Security />
                        </div>
                        <div style={{color:'#888', position:'absolute', bottom:20}}>
                            <span className='narrow-font'>Privacy & Security Optimized</span>
                        </div>
                    </div>
                </div>
                <div style={{height:'50px'}}></div>
                <p style={{fontWeight:'600'}}>
                    We value the trust you place in us and recognize the importance of secure transactions and information privacy. Our Privacy Policy describes how {title} and its affiliates (collectively “{webTitle}, we, our, us”) collect, use, share or otherwise process your personal information through {webTitle} websites {allWebsites.map(item=><span><span style={{color:'green', fontStyle:'italic'}}>{item}</span>, </span>)}mobile application, and m-site (hereinafter referred to as the “Platform”).
                </p>
                <p style={{fontWeight:'600'}}>
                    You can read more about our  <Link to='privacy-policy'>Privacy Policy</Link> and <Link to='terms-of-use'>Terms Of Use</Link>. If you do not agree please do not use or access our Platform.
                </p>

                <p>{webTitle} respects your privacy and is committed to protecting it.</p>

                <article className='payments-article'>
                    <div className='qna'>
                        <h4 className='q'>Is making online payment secure on {webTitle}?</h4>
                        <span className='a'>Yes, making the online payment is secure on {webTitle}.</span>
                    </div>
                    <div className='qna'>
                        <h4 className='q'>Does {webTitle} store my credit/debit card information?</h4>
                        <span className='a'>No. {webTitle} only stores the last 4 digits of your card number for the purpose of card identification.</span>
                    </div>
                    <div className='qna'>
                        <h4 className='q'>Does {webTitle} share my personal information with third party service providers?</h4>
                        <span className='a'>We understand that your privacy is important to you, and we appreciate your trust in us. When you use our services, you agree to share some of your personal information with third party service providers who help us fulfill your needs. We want to assure you that we only share the information that is necessary for the service providers to complete the task, and we do not sell or disclose your information to anyone else. We have strict policies and procedures in place to protect your data and ensure that it is used in accordance with your consent and our terms of service. You can always review and update your privacy settings on our website or contact us if you have any questions or concerns. Thank you for choosing us as your partner.</span>
                    </div>
                </article>
                <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
            </div>
        </div>
    )
}

export default SecurityInfo;