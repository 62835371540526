import { ComingSoon } from "../../pages/ComingSoon";
import ReturnPolicy from "../../pages/ReturnPolicy"; 
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsOfUse from "../../pages/TermsOfUse";
import { Page404 } from "../../pages/Page404";
import { Payments } from "../../pages/help/Payments";
import { RefundsCancellation } from "../../pages/help/RefundsCancellation";
import SecurityInfo from "../../pages/SecurityInfo";
import { CraftedProducts } from "../../pages/quality-checks/CraftedProducts";
import About from "../../pages/About";
import Contact from "../../pages/Contact";
import StoreLocation from "../../pages/StoreLocation";
import Feedback from "../../pages/feedback/Feedback";
import ShippingPolicy from "../../pages/ShippingPolicy";

export function UrlMatcher ({path}){
    switch(path){
        // HomePage
        case '/':   return <ComingSoon />;
        case '/about': return <About />;
        case '/contact': return <Contact />;

        // Policies
        case '/return-policy': return <ReturnPolicy />;
        case '/privacy-policy': return <PrivacyPolicy />;
        case '/terms-of-use': return <TermsOfUse />;
        case '/security': return <SecurityInfo />;
        case '/shipping-policy': return <ShippingPolicy />;

        // help/
        case '/help/payments': return <Payments />;
        case '/help/refunds-cancellation': return <RefundsCancellation />;
        case '/help/faq': return <Page404 />;

        // quality-checks/
        case '/quality-checks/crafted-products': return <CraftedProducts />;

        // Registered address
        case '/store-location': return <StoreLocation />;

        // Feedback
        case '/feedback': return <Feedback />;

        default: return <Page404 />;
    }
}