import { Helmet } from "react-helmet";
import {ReactComponent as TrustBadge} from '../../assets/svg/trust-badge.svg';
import {ReactComponent as Secure} from '../../assets/svg/secure.svg';

import './Payments.scss';
import { AuthenticBadge } from "../../components/badges/AuthenticBadge";
import { SecurePaymentsBadge } from "../../components/badges/SecurePaymentsBadge";
import { webTitle } from "../Constants";
import { SurveyDislikeButton, SurveyLikeButton } from "../../components/button/SurveyButtons";
import { WasThisHelpful } from "../../components/survey/SurveyQuestions";

export const Payments = () =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <Helmet>
                    <title>Help/Payments - {webTitle}</title>
                </Helmet>
                <section>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Payments</h1>
                        <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            {/* Date updated Privacy Policy */}
                            <span style={{fontWeight:'600'}}>28 October, 2023</span>
                        </span>
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    
                    {/* <div className="centered-banner full-width">
                        <div style={{position:'relative', width:'200px', height:'200px', background:'pink', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <TrustBadge />
                            <div style={{position:'absolute', width:'40px', height:'40px'}}>
                                <Secure />
                            </div>
                        </div>
                    </div> */}
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <SecurePaymentsBadge />
                    </div>

                    {/* <p style={{fontWeight:'600', fontStyle:'italic'}}>This return policy is only applicable for purchases made & delivered in India</p> */}
                    <article className="payments-article">
                        <div className="qna">
                            <h4 className="q">How do I pay for a purchase on {webTitle}?</h4>
                            <span className="a">
                                {webTitle} offers you multiple payment methods. Whatever your online mode of payment be, you can rest assured that {webTitle} trusted payment gateway partners use secure encryption technology to keep your transaction details confidential at all times.
                                You may use Internet Banking, Gift Card, and Wallet to make your purchase.
                                {webTitle} also accepts payments made using Visa, MasterCard, Maestro and American Express credit/debit cards in India.
                            </span>
                        </div>
                        <div className="qna">
                            <h4 className="q">Are there any hidden charges (Sales Tax) when I make a purchase on {webTitle}?</h4>
                            <span className="a">
                                There are NO hidden charges when you make a purchase on {webTitle}. The prices listed for all the items are final and all-inclusive. The price you see on the product page is exactly what you pay.
                                Delivery charges may be applicable as mentioned for the items listed.
                            </span>
                        </div>
                        <div className="qna">
                            <h4 className="q">How do I pay using a credit/debit card?</h4>
                            <span className="a">We accept payments made by credit/debit cards issued in India.</span>

                            <div className="detailed-answer">
                                <h5 className="title">Credit cards</h5>
                                <p>
                                    We accept payments made using Visa, MasterCard and American Express credit cards. To pay using your credit card at checkout, you will need your card number, expiry date, three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to the bank's page for entering the online 3D Secure password.
                                </p>
                                <h5 className="title">Debit cards</h5>
                                <p>
                                    We accept payments made using Visa, MasterCard and Maestro debit cards. To pay using your debit card at checkout, you will need your card number, expiry date (optional for Maestro cards), three-digit CVV number (optional for Maestro cards). You will then be redirected to your bank's secure page for entering your online password (issued by your bank) to complete the payment.
                                </p>
                            </div>
                        </div>

                        <div className="qna">
                            <h4 className="q">Is it safe to use my credit/debit card on {webTitle}?</h4>
                            <span className="a">
                                Your online transaction on {webTitle} is secure as encryption technology is used to protect your card information while securely transmitting it to the respective banks for payment processing. All credit card and debit card payments on {webTitle} are processed through secure and trusted payment gateways managed by leading banks. Banks now use the 3D Secure password service for online transactions, providing an additional layer of security through identity verification.
                            </span>
                        </div>
                        <div className="qna">
                            <h4 className="q">Can I use my bank's Internet Banking feature to make a payment?</h4>
                            <span className="a">Yes. {webTitle} offers you the convenience of using your bank's Internet Banking service to make a payment towards your order. With this you can directly transfer funds from your bank account, while conducting a highly secure transaction.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">Can I make a credit/debit card or Internet Banking payment on {webTitle} through my mobile?</h4>
                            <span className="a">Yes, you can make credit card payments through the {webTitle} mobile site. Your card information will be protected by encryption technology while securely transmitting it to the secure and trusted payment gateways managed by leading banks.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">What other payment options are available on {webTitle}?</h4>
                            <span className="a">Apart from Credit and Debit Cards, we accept payments via Internet Banking (covering 44 banks), Cash on Delivery, UPI, and Wallet.</span>
                        </div>
                    </article>

                    <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
                </section>
            </div>
        </div>
    )
}