import {ReactComponent as Like} from '../../assets/svg/survey-like.svg';
import {ReactComponent as Dislike} from '../../assets/svg/survey-dislike.svg';
import './Button.scss';

export const SurveyLikeButton = ({handleClick}) =>{
    return (
        <button className='survey-button hover-green' onClick={handleClick}>
            <Like />
        </button>
    )
}

export const SurveyDislikeButton = ({handleClick}) =>{
    return (
        <button className='survey-button hover-red' onClick={handleClick}>
            <Dislike />
        </button>
    )
}

