import React, { useEffect, useState } from 'react';
import {ReactComponent as LikeEmoji} from '../../../assets/svg/emoji/like_emoji.svg';
import {ReactComponent as DislikeEmoji} from '../../../assets/svg/emoji/dislike_emoji.svg';
// import {ReactComponent as LoveEmoji} from '../../assets/svg/emoji/love_emoji.svg';
// import {ReactComponent as ConfusedEmoji} from '../../assets/svg/emoji/confused_emoji.svg';
// import {ReactComponent as CryEmoji} from '../../assets/svg/emoji/cry_emoji.svg';

export const QuestionRatingEmoji =(props)=>{
    const [ratingValue, setRatingValue] = useState(null);

    useEffect(()=>{
        switch(ratingValue) {
            case 0: 
                props.setRatingValue('[1] of 3 (Poor)')
                return; 
            case 1:
                props.setRatingValue('[2] of 3 (Ok)')
                return; 
            case 2: 
                props.setRatingValue('[3] of 3 (Good)')
                return; 
    
            default: break;
        }
    }, [ratingValue])
    
    return(
        <div style={{padding:'0px 20px', marginTop:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <span style={{marginBottom:'30px'}}>{props.question? props.question:'How likely are you to recommend us?'}</span>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div style={{display:'grid', gridTemplateColumns:'1fr 3fr 1fr', gap:'20px'}}>
                    <div className='flex-cc' style={{width:'100%', height:'100%'}}>
                        <div className='flex-cc' style={{width:'20px', height:'20px', background:'#ccc', borderRadius:'50%', padding:'5px'}}>
                            <DislikeEmoji />
                        </div>
                    </div>
                    <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr'}}>
                        <div className='flex-c-c pointer' 
                            style={{width:'35px', padding:'10px', borderRadius:'50%'}}
                            onClick={()=>setRatingValue(0)}
                        >
                            {/* <CryEmoji /> */}
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    viewBox="0 0 512.001 512.001" style={{enableBackground:'new 0 0 512.001 512.001'}}>
                                <circle style={{fill:ratingValue===0?'#F7B239':'none'}} cx="256.005" cy="256.004" r="246.855"/>
                                <g>
                                    <path style={{fill:props.ratingValue===0?'#E09B2D':'none'}} d="M126.307,385.694c-88.801-88.802-95.798-228.426-20.998-325.241
                                        c-8.286,6.401-16.258,13.399-23.858,20.999c-96.401,96.401-96.401,252.698,0,349.099s252.698,96.401,349.099,0
                                        c7.599-7.599,14.597-15.573,20.999-23.858C354.733,481.492,215.109,474.495,126.307,385.694z"/>
                                    <path style={{fill:'#E09B2D'}} d="M231.898,360.393c-3.406,0-6.676-1.91-8.252-5.185c-2.191-4.553-0.276-10.021,4.277-12.212
                                        c18.369-8.838,37.786-8.838,56.151,0c4.553,2.191,6.469,7.659,4.277,12.213c-2.191,4.553-7.661,6.467-12.212,4.277
                                        c-13.177-6.341-27.107-6.34-40.284,0C234.58,360.102,233.229,360.393,231.898,360.393z"/>
                                </g>
                                <path style={{fill:'#2BA5F7'}} d="M77.509,413.974L77.509,413.974c-22.743-22.743-22.743-59.616,0-82.358l41.179-41.179l41.179,41.179
                                    c22.742,22.742,22.742,59.616,0,82.358l0,0C137.125,436.716,100.253,436.716,77.509,413.974z"/>
                                <path style={{fill:'#2197D8'}} d="M106.787,413.974L106.787,413.974c-22.742-22.742-22.742-59.616,0-82.358l26.541-26.541
                                    l-14.639-14.639l-41.179,41.179c-22.742,22.743-22.742,59.616,0,82.358l0,0c15.127,15.127,36.503,20.179,55.818,15.182
                                    C123.605,426.641,114.402,421.589,106.787,413.974z"/>
                                <path d="M256,0C114.842,0,0.001,114.841,0.001,256.001S114.842,512.001,256,512.001c141.159,0,256.001-114.841,256.001-256.001
                                    S397.16,0,256,0z M83.979,338.085l34.71-34.71l34.71,34.71c19.138,19.138,19.138,50.28,0,69.419
                                    c-19.138,19.138-50.28,19.14-69.419,0C64.84,388.365,64.84,357.224,83.979,338.085z M256,493.701
                                    c-57.82,0-110.877-20.761-152.129-55.207c4.875,1.092,9.845,1.655,14.817,1.655c17.256,0,34.512-6.568,47.65-19.706
                                    c26.274-26.274,26.274-69.024,0-95.298l-41.179-41.179c-1.715-1.716-4.043-2.68-6.469-2.68c-2.426,0-4.754,0.964-6.469,2.68
                                    l-41.179,41.179C56.836,339.351,50.328,358.371,51.482,377c-21.065-35.467-33.182-76.843-33.182-121
                                    C18.3,124.932,124.932,18.299,256,18.299c131.069,0,237.702,106.632,237.702,237.702S387.069,493.701,256,493.701z"/>
                                <path d="M174.858,149.552c-31.233,1.474-58.686,18.813-73.44,46.381c-2.384,4.455-0.705,10,3.75,12.385
                                    c1.375,0.736,2.852,1.085,4.31,1.085c3.266,0,6.427-1.754,8.075-4.833c11.686-21.836,33.43-35.569,58.168-36.736
                                    c5.048-0.238,8.946-4.524,8.708-9.57C184.191,153.216,179.899,149.296,174.858,149.552z"/>
                                <path d="M337.142,149.552c-5.053-0.245-9.331,3.66-9.57,8.708c-0.239,5.048,3.66,9.333,8.708,9.57
                                    c24.738,1.167,46.485,14.9,58.169,36.736c1.649,3.082,4.808,4.835,8.075,4.835c1.455,0,2.934-0.349,4.309-1.085
                                    c4.454-2.385,6.134-7.93,3.749-12.384C395.831,168.365,368.376,151.026,337.142,149.552z"/>
                                <path d="M314.46,318.618c-32.779-19.764-84.136-19.764-116.918,0c-4.327,2.609-5.72,8.232-3.111,12.559
                                    c2.609,4.327,8.233,5.72,12.559,3.111c26.565-16.017,71.453-16.017,98.022,0c1.477,0.891,3.106,1.315,4.715,1.315
                                    c3.101,0,6.125-1.577,7.844-4.426C320.18,326.85,318.787,321.227,314.46,318.618z"/>
                                <path d="M146.724,203.59c-18.666,0-33.852,15.186-33.852,33.852s15.186,33.852,33.852,33.852s33.852-15.186,33.852-33.852
                                    S165.39,203.59,146.724,203.59z"/>
                                <path d="M365.276,203.59c-18.666,0-33.852,15.186-33.852,33.852s15.186,33.852,33.852,33.852s33.852-15.186,33.852-33.852
                                    S383.942,203.59,365.276,203.59z"/>
                                <g>
                                    <circle style={{fill:'#FFFFFF'}} cx="158.812" cy="230.105" r="9.15"/>
                                    <circle style={{fill:'#FFFFFF'}} cx="377.181" cy="230.105" r="9.15"/>
                                </g>
                            </svg>
                        </div>
                        <div className='flex-c-c pointer' 
                            style={{width:'35px', padding:'10px', borderRadius:'50%'}}
                            onClick={()=>setRatingValue(1)}    
                        >
                            {/* <ConfusedEmoji /> */}
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    viewBox="0 0 512.001 512.001" style={{enableBackground:'new 0 0 512.001 512.001'}}>
                                <circle style={{fill:ratingValue===1?'#F7B239':'none'}} cx="256.004" cy="256.004" r="246.855"/>
                                <g>
                                    <path style={{fill:props.ratingValue===1?'#E09B2D':'none'}} d="M126.306,385.694c-88.801-88.802-95.798-228.426-20.998-325.241
                                        c-8.286,6.401-16.258,13.399-23.858,20.999c-96.401,96.401-96.401,252.698,0,349.099s252.698,96.401,349.099,0
                                        c7.599-7.599,14.597-15.573,20.999-23.858C354.733,481.492,215.108,474.495,126.306,385.694z"/>
                                    <path style={{fill:'#E09B2D'}} d="M297.481,349.115h-85.403c-5.054,0-9.15-4.097-9.15-9.15s4.095-9.15,9.15-9.15h85.403
                                        c5.054,0,9.15,4.097,9.15,9.15S302.534,349.115,297.481,349.115z"/>
                                </g>
                                <path d="M256.001,0C114.841,0,0,114.841,0,256.001s114.841,256.001,256.001,256.001S512.001,397.16,512.001,256.001
                                    S397.16,0,256.001,0z M256.001,493.701c-131.069,0-237.702-106.631-237.702-237.7S124.932,18.299,256.001,18.299
                                    s237.702,106.632,237.702,237.702S387.068,493.701,256.001,493.701z"/>
                                <path d="M371.284,296.658H138.275c-5.054,0-9.15,4.097-9.15,9.15s4.095,9.15,9.15,9.15h233.008c5.054,0,9.15-4.097,9.15-9.15
                                    C380.433,300.754,376.337,296.658,371.284,296.658z"/>
                                <path d="M180.577,226.834c0-18.666-15.186-33.852-33.852-33.852s-33.852,15.186-33.852,33.852s15.186,33.852,33.852,33.852
                                    S180.577,245.501,180.577,226.834z"/>
                                <path d="M365.275,192.982c-18.666,0-33.852,15.186-33.852,33.852s15.186,33.852,33.852,33.852s33.852-15.186,33.852-33.852
                                    S383.942,192.982,365.275,192.982z"/>
                                <g>
                                    <circle style={{fill:'#FFFFFF'}} cx="155.969" cy="219.735" r="9.15"/>
                                    <circle style={{fill:'#FFFFFF'}} cx="374.338" cy="219.735" r="9.15"/>
                                </g>
                            </svg>
                        </div>
                        <div className='flex-c-c pointer' 
                            style={{width:'35px', padding:'10px', borderRadius:'50%'}}
                            onClick={()=>setRatingValue(2)}    
                        >
                            {/* <LoveEmoji /> */}
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                                    viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}}>
                                <circle style={{fill:ratingValue===2?'#F7B239':'none'}} cx="256.004" cy="256.004" r="246.855"/>
                                <path style={{fill:props.ratingValue===2? '#E09B2D':'none'}} d="M126.306,385.694c-88.801-88.802-95.798-228.426-20.998-325.242
                                    C97.023,66.853,89.051,73.85,81.45,81.45c-96.401,96.401-96.401,252.698,0,349.099s252.698,96.401,349.099,0
                                    c7.599-7.599,14.597-15.573,20.999-23.858C354.733,481.492,215.108,474.494,126.306,385.694z"/>
                                <g>
                                    <path style={{fill:'#F95428'}} d="M353.569,137.751L353.569,137.751c-14.911-14.911-39.087-14.911-53.998,0l0,0
                                        c-14.911,14.911-14.911,39.087,0,53.998l0,0l53.998,53.998l53.998-53.998l0,0c14.911-14.911,14.911-39.087,0-53.998l0,0
                                        C392.656,122.839,368.48,122.839,353.569,137.751L353.569,137.751z"/>
                                    <path style={{fill:'#F95428'}} d="M158.431,137.751L158.431,137.751c-14.911-14.911-39.087-14.911-53.998,0l0,0
                                        c-14.911,14.911-14.911,39.087,0,53.998l0,0l53.998,53.998l53.998-53.998l0,0c14.911-14.911,14.911-39.087,0-53.998l0,0
                                        C197.518,122.839,173.342,122.839,158.431,137.751L158.431,137.751z"/>
                                </g>
                                <path style={{fill:'#FFFFFF'}} d="M131.567,281.654h248.868c0,13.968-2.306,27.4-6.551,39.941H138.118
                                    C133.872,309.053,131.567,295.622,131.567,281.654z"/>
                                <path style={{fill:'#A81004'}} d="M138.118,321.594h235.765c-8.369,24.716-24.289,45.931-45.016,60.924
                                    c-8.71-14.676-24.716-24.509-43.027-24.509c-11.187,0-21.508,3.672-29.84,9.882c-8.332-6.21-18.665-9.882-29.852-9.882
                                    c-18.299,0-34.317,9.833-43.015,24.509C162.407,367.525,146.487,346.31,138.118,321.594z"/>
                                <path style={{fill:'#F95428'}} d="M285.84,358.009c18.311,0,34.317,9.833,43.027,24.509c-20.483,14.834-45.662,23.569-72.867,23.569
                                    s-52.384-8.735-72.867-23.569c8.698-14.676,24.716-24.509,43.015-24.509c11.187,0,21.52,3.672,29.852,9.882
                                    C264.333,361.682,274.653,358.009,285.84,358.009z"/>
                                <path d="M255.999,512C114.841,512,0,397.159,0,255.999C0,114.841,114.841,0,255.999,0C397.159,0,512,114.841,512,255.999
                                    C512,397.159,397.159,512,255.999,512z M255.999,18.299c-131.068,0-237.7,106.632-237.7,237.7
                                    c0,131.069,106.632,237.702,237.7,237.702c131.069,0,237.702-106.632,237.702-237.702
                                    C493.701,124.932,387.068,18.299,255.999,18.299z"/>
                                <path d="M353.569,254.897c-2.341,0-4.683-0.893-6.469-2.68l-53.998-53.998c-8.94-8.94-13.863-20.826-13.863-33.469
                                    s4.924-24.529,13.863-33.469s20.826-13.863,33.469-13.863c9.794,0,19.132,2.953,27,8.441c7.867-5.487,17.206-8.441,26.999-8.441
                                    c12.643,0,24.529,4.924,33.469,13.863c18.454,18.455,18.454,48.483,0,66.938l-53.998,53.998
                                    C358.252,254.004,355.911,254.897,353.569,254.897z M326.569,135.717c-7.755,0-15.046,3.021-20.529,8.503
                                    c-5.484,5.484-8.504,12.774-8.504,20.529s3.021,15.046,8.504,20.529l47.529,47.529l47.529-47.529c11.32-11.32,11.32-29.738,0-41.059
                                    c-5.484-5.484-12.775-8.503-20.53-8.503s-15.046,3.021-20.529,8.503c-1.715,1.715-4.043,2.68-6.469,2.68
                                    c-2.426,0-4.753-0.964-6.469-2.68C341.616,138.736,334.324,135.717,326.569,135.717z"/>
                                <path d="M158.431,254.897c-2.341,0-4.683-0.893-6.469-2.68l-53.998-53.998c-18.454-18.455-18.454-48.483,0-66.938
                                    c8.94-8.94,20.826-13.863,33.469-13.863c9.792,0,19.132,2.953,26.998,8.441c7.867-5.487,17.206-8.441,27-8.441
                                    c12.642,0,24.529,4.924,33.469,13.863c18.455,18.454,18.455,48.483,0,66.938L164.9,252.216
                                    C163.114,254.004,160.772,254.897,158.431,254.897z M131.431,135.717c-7.755,0-15.046,3.021-20.529,8.503
                                    c-11.32,11.32-11.32,29.738,0,41.059l47.529,47.529l47.529-47.529c11.32-11.32,11.32-29.738,0-41.059
                                    c-5.484-5.484-12.775-8.503-20.53-8.503c-7.755,0-15.046,3.021-20.529,8.503c-1.715,1.715-4.043,2.68-6.469,2.68
                                    c-2.426,0-4.754-0.964-6.471-2.68C146.477,138.736,139.187,135.717,131.431,135.717z"/>
                                <path d="M256.001,415.237c-28.32,0-55.373-8.752-78.235-25.309c-22.673-16.401-39.381-39.017-48.314-65.4
                                    c-4.667-13.788-7.034-28.214-7.034-42.876c0-5.053,4.097-9.15,9.15-9.15h248.868c5.053,0,9.15,4.097,9.15,9.15
                                    c0,14.661-2.367,29.086-7.034,42.875c0,0,0,0,0,0.001c-8.934,26.382-25.643,48.998-48.319,65.402
                                    C311.372,406.486,284.32,415.237,256.001,415.237z M141.074,290.803c0.745,9.506,2.657,18.842,5.709,27.857
                                    c7.708,22.762,22.131,42.279,41.712,56.444c19.723,14.284,43.064,21.833,67.504,21.833s47.781-7.549,67.5-21.83
                                    c19.585-14.167,34.008-33.686,41.716-56.448v0.001c3.052-9.017,4.964-18.353,5.711-27.857H141.074V290.803z"/>
                                <rect x="138.122" y="312.451" width="235.765" height="18.299"/>
                                <path d="M321.787,388.567c-0.248-0.466-0.516-0.921-0.782-1.368c-7.339-12.364-20.811-20.04-35.166-20.04
                                    c-8.862,0-17.29,2.79-24.372,8.069c-3.244,2.418-7.691,2.418-10.934,0c-7.083-5.279-15.515-8.069-24.385-8.069
                                    c-14.357,0-27.824,7.673-35.145,20.024c-0.274,0.462-0.543,0.917-0.791,1.383l-16.161-8.583c0.387-0.73,0.801-1.446,1.219-2.146
                                    c10.59-17.868,30.09-28.977,50.878-28.977c10.629,0,20.83,2.772,29.852,8.066c9.02-5.296,19.216-8.066,29.84-8.066
                                    c20.78,0,40.284,11.108,50.896,28.988c0.41,0.689,0.825,1.405,1.213,2.135L321.787,388.567z"/>
                            </svg>
                        </div>
                    </div>
                    <div className='flex-cc' style={{width:'100%', height:'100%'}}>
                        <div className='flex-cc' style={{width:'20px', height:'20px', background:'#ccc', borderRadius:'50%', padding:'5px'}}>
                            <LikeEmoji />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}