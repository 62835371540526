import React from 'react';
import { Helmet } from 'react-helmet';
import Tag from '../components/Tag/Tag';
import { clickToWhatsAppChat, webTitle, whatsAppOrderDamage } from './Constants';

import './ReturnPolicy.scss';
import { WasThisHelpful } from '../components/survey/SurveyQuestions';
import { Link } from '../components/extraFunctions/Link';

const ShippingPolicy=()=>{
    window.scrollTo({ top: 0, behavior:'smooth'});
    return(
        <div className='flex-cc'>
            <Helmet>
                <title>Shipping policy - {webTitle}</title>
            </Helmet>
            <div className='flex-cc' style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem'}}>
                <section style={{width:'90%'}}>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Shipping Policy</h1>
                        <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            {/* Date updated Privacy Policy */}
                            <span style={{fontWeight:'600'}}>21 May, 2024</span>
                        </span>
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    {/* <div className='warning-notice'> 
                        <div className='warning-notice-title'>
                            <span>Warning</span>
                        </div>
                        <div className='warning-notice-msg'>
                            <span>
                                No Return or Replacement or Refund for Orders delivered outside <span style={{fontWeight:'600', fontStyle:'italic'}}>India</span>
                            </span>
                        </div>
                    </div> */}
                    {/* <p style={{fontWeight:'600', fontStyle:'italic'}}>This return policy is only applicable for purchases made & delivered in India</p>
                    <p>Do refer the respective item's applicable return/replacement terms on the product page for any exceptions to this return policy. 
                        For all products, the returns/replacement terms provided on the product page shall prevail over the general return policy.
                    </p>
                    <div style={{overflowX:'auto'}}>
                        <table className='cell-partitioned-table'>
                            <thead>
                                <tr style={{textAlign:'center'}}>
                                    <th>Category</th>
                                    <th>Return Period</th>
                                    <th>Return Terms</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{textAlign:'center'}}>
                                    <td>
                                        Craft Items
                                        <br/>
                                        <span style={{fontSize:'0.8rem', fontStyle:'italic'}}>{'('}Hand Crafted / Machine Crafted{')'}</span>
                                    </td>
                                    <td>4 days</td>
                                    <td style={{paddingRight:'10px', width:'60%', textAlign:'justify'}}>
                                        <ul>
                                            <li>Product should be completely unused. Scratches or any kind of damage will not be accepted.</li>
                                            <li>Product should be returned in the original packaging, and should be packed with a high degree of resistibility to damage.</li>
                                            <li>If any other offers were included with the purchase, everything should be returned as a single package. Partial return of an item in a combo is not a valid return and <b>we will not be responsible if the status of the Order</b> stands as <Tag label='Item yet to be returned' fontSize='0.8rem'/></li>
                                            <li>If the Order is not returned within 5 days of return request initiation, the Order status will be changed to <Tag label='Delivered' fontSize='0.8rem' bgColor='green'/> and will not be available for any return request after that.</li>
                                            <br/>
                                            <span><em><b>Note</b></em></span>
                                            <li>
                                                In-case a damaged product was delivered, please make sure to record the complete unboxing video, and send it to our WhatsApp number <b>{whatsAppOrderDamage}</b> with the <b>Order#</b> or <a href={clickToWhatsAppChat} target='_blank' rel="noreferrer"><Tag label='Send with WhatsApp' fontSize='0.8rem' bgColor='lightgreen' textColor='green'/></a>
                                                <br/>
                                                <em style={{fontSize:'0.8rem'}}>{'('}To make sure you record any damaged package, we recommend recording every unboxing video. It will help in easy return/replacement for your damaged order{')'}</em>
                                            </li>
                                            <li>
                                                In-case a replacement product is not available we will offer you to take a refund. The refund amount will be credited to the account you choose. The bank account must be owned by an Indian {'(age 18 years or above)'}, and must be opened within banks operational in India.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}

                    <p>
                        At SG Marbles, we understand that the timely and safe delivery of your marble, granite, tiles, and marble artifacts is crucial. Our shipping policy is designed to provide you with a seamless experience, ensuring your products arrive in perfect condition.
                    </p>
                    <ol>
                        <li><strong>Order Processing:</strong> Orders are processed within 2-3 business days. Custom orders may take longer, and customers will be notified of the expected processing time upon order confirmation.</li>
                        <br/>
                        <li><strong>Shipping Methods:</strong> We offer various shipping methods to meet your needs, including standard, express, and freight services for larger orders.</li>
                        <br/>
                        <li><strong>Shipping Cost:</strong> ONLY APPLICABLE ACROSS INDIA
                            <ul>
                                <li><strong><em>Standard</em></strong>: Free delivery for small products {'(Gross weight < 800gm)'}. For larger products the shipping cost is calculated based on the weight, dimensions, and destination of the order.</li>
                                <li><strong><em>Express</em></strong>: <span style={{color:'darkgreen', fontStyle:'italic'}}>Only available for small products.</span> While express delivery aims for speed, unforeseen circumstances can occasionally disrupt our plans. An additional charge of <strong style={{color:'darkgreen'}}>₹100</strong> will apply for express delivery.</li>
                            </ul>
                        </li>
                        <br/>
                        <li>
                            <strong>International Shipping:</strong> Upon submitting a request form to change the delivery address to any other country, we offer international shipping. By doing so, you also agree to our <Link to='terms-of-use'>terms</Link> and <Link to='return-policy'>return policy</Link>. 
                            <br/>The shipping charges vary by country. Customers are responsible for any customs duties, taxes, or other fees incurred. 
                            <br/><em>Internationally shipped items do not qualify for returns, refunds or replacements.</em>
                        </li>
                        <br/>
                        <li><strong>Delivery Times:</strong> Delivery times depend on the shipping method selected. Standard shipping typically takes 4-9 business days, while express shipping can reduce this time. Freight services are scheduled according to availability. International shipping may take upto 15-21 business days.</li>
                        <br/>
                        <li><strong>Packaging:</strong> All items are packed with the utmost care, using materials that provide maximum protection.</li>
                        <br/>
                        <li><strong>Tracking:</strong> Once shipped, customers will receive a tracking number to monitor the delivery status of their order.</li>
                        <br/>
                        <li><strong>Damages:</strong> In the rare event that your order arrives damaged, please contact us within 48 hours of delivery with unboxing video evidence so we can arrange for a replacement or refund. No return / refund or replacement for orders delivered outside India.</li>
                        <br/>
                        <li><strong>Returns:</strong> If you wish to return an item, please refer to our Return Policy. Returns must be made in the original packaging.</li>
                    </ol>


                    <p>
                        Please note that shipping policies are subject to change based on carrier rates and policies.
                    </p>
                    <p style={{fontWeight:'600', fontStyle:'italic'}}>We reserve the right to amend this policy at any time without prior notice.</p> 
                    <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
                </section>
            </div>
        </div>
    )
}

export default ShippingPolicy;