import { ReactComponent as Page404Image } from "../assets/svg/404.svg";
import { Button, ButtonTakeMeToUrl } from "../components/button/Button";
import { Link } from "../components/extraFunctions/Link";
import { shopHostname } from "./Constants";
export const Page404 =() =>{
    window.scrollTo({ top: 0, behavior:'smooth'});
    return (
        <div style={{height:'500px'}}>
            <div style={{marginTop:'200px', height:'60%', display:'flex', justifyContent:'center'}}>
                <Page404Image />
            </div>
            <br/>
            <div style={{margin:'0px 20px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <span style={{color:'#888', fontSize:'1.3rem'}}>
                    Page Not Found
                    <br/>
                    <span style={{color:'#888', fontSize:'0.8rem', lineHeight:'-2'}}>Check for any typos/spelling errors in the URL
                    </span>
                </span>
                {/* <ReportPage uid={props.uid} label='Report Page Not Found' error='[Error: 404] Page Not Found'/> */}
                <br/>
                <br/>
                <div style={{width:'300px', display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                    {/* <Link className='text-button'
                        onClick={()=>{window.history.go(-1)}}
                    >
                        Go back
                    </Link> */}
                    <a style={{height:'100%', textDecoration:'underline'}} className='pointer' onClick={()=>{window.history.go(-1)}}>Go back</a>
                    {/* <a href={'https://shop.sgmarbles.com'} style={{textDecoration:'none', color:'black'}} */}
                        {/* className='checkout-button outlined-button pointer click-animation proceed-to-checkout'> */}
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', fontSize:'1rem'}}>
                        <ButtonTakeMeToUrl
                            className={'light-button'}
                            takeMeTo={{to:'', hostname:shopHostname}}
                            style={{fontWeight:'600'}}
                        >
                            Shop Now
                        </ButtonTakeMeToUrl>
                        {/* Shop */}
                        {/* <div style={{width:'20px', paddingLeft:'10px'}}><Bag/></div> */}
                    </div>
                    {/* </a> */}
                </div>
            </div>
        </div>
    )
}