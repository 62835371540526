import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { title, webTitle, website, complaintsEmail, unsubscribeFromPromoEmailsURL, grievanceOfficerName, grievanceOfficerEmail, supportTeamEmail, onlineStoreWebsite, blogWebsite, allWebsites } from './Constants';
import './PrivacyPolicy.scss';
import { WasThisHelpful } from '../components/survey/SurveyQuestions';

const PrivacyPolicy =() =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    // useEffect(()=>{
    //     window.scrollTo({ top:0, behavior:'smooth'})
    // })
    return(
        <div style={{display:'flex', justifyContent:'center'}}>
            {/* <div style={{margin:'0 300px', textAlign:'left', paddingTop:'150px', textAlign:'justify', fontSize:'0.9rem'}}> */}
            <Helmet>
                <title>Privacy policy - {webTitle}</title>
            </Helmet>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem'}}>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <h1>Privacy Policy</h1>
                    <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                        {/* Date updated Privacy Policy */}
                        <span style={{fontWeight:'600'}}>21 May, 2024</span>
                    </span>
                </div>
                <div style={{height:'50px'}}></div>
                <p style={{fontWeight:'600'}}>
                    We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy describes how {title} and its affiliates (collectively “{webTitle}, we, our, us”) collect, use, share or otherwise process your personal information through {webTitle} websites {allWebsites.map(item=><span><span style={{color:'green', fontStyle:'italic'}}>{item}</span>, </span>)}mobile application, and m-site (hereinafter referred to as the “Platform”).
                </p>
                <p style={{fontWeight:'600'}}>
                    While you may be able to browse certain sections of the Platform without registering with us, however, please note we offer most of the product/service under this Platform in India and only offer a few outside India. By visiting this Platform, providing your information or availing our product/service, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.
                </p>
                    {/* # 1 */}
                <ol>
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>1. Collection of Your Information</span>
                        <span className='span-para'>
                            When you use our Platform, we collect and store your information which is provided by you from time to time. In general, you can browse the Platform without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service, product or feature on the Platform.
                        </span>
                        <span className='span-para'>
                            We may track your buying behaviour, preferences, and other information that you choose to provide on our Platform. We use this information to do internal research on our users' demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Platform or not), which URL you next go to (whether this URL is on our Platform or not), your computer browser information, and your IP address.
                        </span>
                        <span className='span-para'>
                            We collect personal information (such as email address, delivery address, name, phone number, credit card/debit card and other payment instrument details) from you when you set up an account or transact with us. While you can browse some sections of our Platform without being a registered member, certain activities (such as placing an order or consuming our online content or services) do require registration. We do use your contact information to send you offers based on your previous orders and your interests.
                        </span>
                        <span className='span-para'>
                            If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback or if you use voice commands to shop on the Platform, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.
                        </span>
                        <span className='span-para'>
                            If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information into a file specific to you.
                        </span>
                        <span className='span-para'>
                            We will collect your information related to your transactions on {webTitle} platform.
                        </span>
                    </li>

                    {/* # 2 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>2. Use of Demographic / Profile Data / Your Information</span>
                        <span className='span-para'>We use your personal information to provide the product and services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services; inform you about online and offline offers, products, services, and updates; customize and enhance your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection of information.</span>

                        <span className='span-para'>With your consent, we will have access to your SMS, contacts in your directory, location and device information. We may also request you to provide your PAN, GST Number, Government issued ID cards/number and Know-Your-Customer (KYC) details to: (i) check your eligibility for certain products and services including but not limited to credit and payment products; (ii) issue GST invoice for the products and services purchased for your business requirements; (iii) enhance your experience on the Platform and provide you access to the products and services being offered by us, sellers, affiliates or lending partners. You understand that your access to these products/services may be affected in the event consent is not provided to us.</span>

                        <span className='span-para'>In our efforts to continually improve our product and service offerings, we and our affiliates collect and analyse demographic and profile data about our users' activity on our Platform. We identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify you and to gather broad demographic information.</span>

                        <span className='span-para'>We will occasionally ask you to participate in optional surveys conducted either by us or through a third party market research agency. These surveys may ask you for personal information, contact information, date of birth, demographic information (like zip code, age, or income level), attributes such as your interests, household or lifestyle information, your purchasing behaviour or history, preferences, and other such information that you may choose to provide. The surveys may involve collection of voice data or video recordings, the participation of which would purely be voluntary in nature. We use this data to tailor your experience at our Platform, providing you with content that we think you might be interested in and to display content according to your preferences.</span>
                    </li>

                    {/* # 3 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>3. Cookies</span>
                        <span className='span-para'>
                            We use data collection devices such as "cookies" on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal information. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics help us understand how our customers use the site. You can read more about how Google uses your Personal Information <a href='https://www.google.com/intl/en/policies/privacy/' target='_blank' rel='noreferrer'>here</a>. You can also opt-out of Google Analytics <a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel='noreferrer'>here</a>. 
                        </span>
                    </li>

                    {/* # 4 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>4. Sharing of personal information</span>
                        <span className='span-para'>
                            We may share your personal information with our other corporate entities and affiliates for purposes of providing products and services offered by them. These entities and affiliates may share such information with their affiliates, business partners and other third parties for the purpose of providing you their products and services, and may market to you as a result of such sharing unless you explicitly opt-out.
                        </span>
                        <span className='span-para'>
                            We may disclose your personal information to third parties, such as sellers, business partners. This disclosure may be required for us to provide you access to our products and services; for fulfilment of your orders; for enhancing your experience; for providing feedback on products; to collect payments from you; to comply with our legal obligations; to conduct market research or surveys; to enforce our Terms of Use; to facilitate our marketing and advertising activities; to analyse data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our product and services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.
                        </span>
                        <span className='span-para'>
                            We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.
                        </span>
                        <span className='span-para'>
                            We and our affiliates will share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to your personal information.
                        </span>
                    </li>
                
                    {/* 5 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>5. Links to Other Sites</span>
                        <span className='span-para'>
                            Our Platform may provide links to other websites or application that may collect personal information about you. We are not responsible for the privacy practices or the content of those linked websites.
                        </span>
                    </li>

                    {/* 6 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>6. Security Precautions</span>
                        <span className='span-para'>
                            We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to our security guidelines to protect it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.
                        </span>
                    </li>

                    {/* 7 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>7. Choice/Opt-Out</span>
                        <span className='span-para'>
                            We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications after setting up an account with us. If you do not wish to receive promotional communications from us then navigate to Notifications tab from your Account Dashboard, and select Communication Preferences to <a href={unsubscribeFromPromoEmailsURL} target='_blank' rel='noreferrer'>unsubscribe/opt-out</a>.
                        </span>
                    </li>

                    {/* 8 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>8. Advertisements on Platform</span>
                        <span className='span-para'>
                            We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.
                        </span>
                    </li>

                    {/* 9 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>9. Children Information</span>
                        <span className='span-para'>
                            We do not knowingly solicit or collect personal information from children under the age of 18 and use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are under the age of 18 years then you must use the Platform, application or services under the supervision of your parent, legal guardian, or any responsible adult.
                        </span>
                    </li>

                    {/* 10 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>10. Data Retention</span>
                        <span className='span-para'>
                            We retain your personal information in accordance with appliable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you if we believe it may be necessary to prevent fraud or future abuse or if required by law or for other legitimate purposes. We may continue to retain your data in anonymised form for analytical and research purposes.
                        </span>
                    </li>

                    {/* 11 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>11. Your Consent</span>
                        <span className='span-para'>
                            By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information (including sensitive personal information) on the Platform in accordance with this Privacy Policy. If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.
                        </span>

                        <span className='span-para'>
                            You, while providing your personal information over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.
                        </span>
                    </li>

                    {/* 12 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>12. Changes to this Privacy Policy</span>
                        <span className='span-para'>
                            We keep our Policy under regular review and may amend it from time to time, at our sole discretion. Any changes shall be deemed effective immediately upon publishing of the modified Policy on the App and any continuous usage of Services, following the modification will indicate acceptance of changes in Policy by you.
                            We may update this Privacy Policy to reflect changes to our information practices. We will alert you to significant changes by posting the date our policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.
                        </span>
                    </li>

                    {/* 13 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>13. Grievance Officer</span>
                        <span className='span-para'>
                            In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                        </span>
                        <span className='span-para'>
                            Name: <span style={{fontWeight:'600'}}>{grievanceOfficerName}</span>,<br/>
                            Email: <span style={{fontStyle:'italic', color: 'green'}}>{grievanceOfficerEmail}</span>
                        </span>
                        <span className='span-para'>
                            Customer Support: You can reach our customer support team to address any of your queries or complaints.<br/>Email: <span style={{fontStyle:'italic', color: 'green'}}>{supportTeamEmail}</span>
                        </span>
                    </li>

                    {/* 14 */}
                    <li style={{display:'flex', flexDirection:'column'}}><span style={{fontWeight:'600', paddingBottom:'10px'}}>14. Queries</span>
                        <span className='span-para'>
                            If you have a query, issue, concern, or complaint in relation to collection or usage of your personal information under this Privacy Policy, please contact <span style={{fontStyle:'italic', color: 'green'}}>{complaintsEmail}</span>
                        </span>
                    </li>
                </ol>
                    
                <p style={{fontWeight:'600', fontStyle:'italic'}}>We reserve the right to amend this policy at any time without prior notice.</p> 

                <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
            </div>
        </div>
    )
}

export default PrivacyPolicy;