import React, { useEffect, useState } from "react";
import { webTitle, subtitle, blogWebsite, blogHostname } from "../../pages/Constants";
import Logo from '../../assets/svg/logo_circle.svg';

import './Header.scss';
import { Link } from "../extraFunctions/Link";
import { BannerMatcher } from "../extraFunctions/BannerMatcher";

const LogoLink = () =>{
    return (
        <Link to='' style={{textDecoration:'none'}}>
            <div className='flex-start'>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img id='header-logo' src={Logo} alt='logo'/>
                </div>
                {/* <div id='header-text'>
                    <span id='header-text-title'>{webTitle}</span>
                    <span id='header-text-subtitle'>{subtitle}</span>
                </div> */}
                {/* <span id='header-text'>{title}</span> */}
            </div>
        </Link>
    )
}

const Header = () =>{
    const [banner, setBanner] = useState(null);

    useEffect(()=>{
        const result = BannerMatcher(window.location.pathname);
        setBanner(result);
    } , [window.location.pathname])
    return (
        <div className="header-top">
            <div style={{
                    maxWidth:'1000px',
                    width:'100%',
                    height:'60px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-around',
                    padding:'0 30px'
                }}
            >
                <LogoLink />
                
                <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', width:'50%', height:'100%'}}>
                    {/* <Link to='stones' className='nav-link hover-underline'>Stones</Link> */}
                    {/* <Link to='login' className='nav-link hover-underline'>Login</Link> */}
                    <Link to='' hostname={blogHostname}
                        className='nav-link hover-underline'
                    >
                        <span>Blog</span>
                    </Link>
                </div>
            </div>

            {/* Anouncement Banner */}
            {
                banner ? 
                
                    <div style={{position:'absolute', top:'60px', width:'100%', left:0}}>
                        {banner}
                    </div>
                : null
            }
        </div>
    )
}

export default Header;