    //privacy policy requirement
    export const websiteURL = 'https://www.sgmarbles.com';
    export const complaintsEmail = 'support@sgmarbles.com';
    export const unsubscribeFromPromoEmailsURL = 'https://www.sgmarbles.in/account/notifications/communication-preferences';
    export const grievanceOfficerName = 'Mr. Ravi Sharma';
    export const grievanceOfficerEmail = 'grievance.officer@sgmarbles.in';
    export const supportTeamName = 'Support';

    // Common for privacy policy and contact us page
    export const supportTeamEmail = 'support@sgmarbles.com';

    // Contact us
    export const supportTeamContact = '+91 7011 67 1004';
    export const hrefSupportContact = 'tel:+917011671004';
    export const hrefSupportEmail = `mailto://${supportTeamEmail}`;

    // Return Policy
    export const whatsAppOrderDamage = '+917011671004';
    export const clickToWhatsAppChat = `https://wa.me/917011671004`;

    //terms of use requirement
    export const jurisdiction = 'Faridabad(HR)';

    // Common for Return Policy, Privacy Policy, Terms Of Use, Help/Payments
    export const webTitle = 'SG Marbles';
    export const website = 'www.sgmarbles.com';
    export const onlineStoreWebsite = 'https://sgmarbles.in';
    export const blogWebsite = 'https://blog.sgmarbles.com';
    export const allWebsites = [
        'www.sgmarbles.com',
        'shop.sgmarbles.com',
        'blog.sgmarbles.com',
        'account.sgmarbles.com',
        'www.sgmarbles.in',
        'pay.sgmarbles.in',
    ]

    // Footer Requirements
    export const registeredAddress = 'DSS 120, Sector-21C, Marble Market, Faridabad, Haryana, 121008'
    export const title = 'Shree Ganesh Italian Granites & Marbles';
    // Footer Social Links and @handles
    export const instagramHandle = 'sgmarbles';
    export const threadsHandle = 'sgmarbles';
    export const socialWhatsAppChatLink = 'https://wa.me/917011671004';
    export const socialWhatsAppChannelLink = 'https://whatsapp.com/channel/0029VaD6NpN89inpXs9YYM0t';
    export const socialInstagramLink = `https://instagram.com/${instagramHandle}`;
    export const socialThreadsLink = `https://threads.net/${threadsHandle}`;

    // Different Hostnames
    export const shopHostname = 'sgmarbles.in';
    export const blogHostname = 'blog.sgmarbles.com';

    // Link to different hostnames
    export const hostnames = [
        'shop.sgmarbles.com',
        'sgmarbles.in',
        'blog.sgmarbles.com'
    ]

    // Store Location
    export const storeLocations = [
        {
            storeName: "shree ganesh italian granites & marbles",
            storeAddress: "DSS 120, Sector-21C, Marble Market, Faridabad, Haryana, 121008",
            storeContact: "+91 7011671004, +91 7982085970",
            directionsRedirect: "https://maps.app.goo.gl/1ArWRpK2vLY22JJbA",
            closedOnDays: ["thursday"]
        }
    ]

    // Header of the app
    export const subtitle =`Nature's best art`;

    // Discord Bot URLs
    export const contactUsDiscordBotURL = "https://discord.com/api/webhooks/1193808163615551599/0ZblHtKOwayerPjs7vfADCfV2N45hN1vcQx9KLSGrr7rMNo3rfEDctaxo8ad50UfTqNG"
    export const feedbackDiscordBotURL = "https://discord.com/api/webhooks/1210224357268525127/qO21Wa-ivNTM9G1uXf_LL8EkwZ40KvkN2iI_KbLSEKnJbjBTw7dRXxeLT-SRVnuP-j9-"

    // Add Customer Feedback
    export const FN_ADD_SHOPPING_FEEDBACK = process.env.REACT_APP_FN_ADD_SHOPPING_FEEDBACK;
