import { hostnames } from "../../pages/Constants";

export const 
Link = ({to, hostname, http, className, style, children}) =>{

    if(hostname){
        switch(hostnames.includes(hostname)){
            case true:
                return (
                    <a className={className? className:null}
                        style={style? style:null} href={`${http?'http':'https'}://${hostname}/${to.length? to:''}`}
                    >
                        {children}
                    </a>
                );
    
            case false:
            default: 
                return (
                    <span>{children}</span>
                )
        }
    }else{
        return (
            <a className={className? className:null} 
                style={style? style:null}  href={`/${to}`}
            >
                {children}
            </a>
        )
    }
}