import './Div.scss';

export const FlexibleDiv = ({children, className, ...otherProps}) =>{
    return (
        <div className={className? `flexible-div ${className}`:'flexible-div'}
            {...otherProps}
        >
            {children}
        </div>
    )
}