import React from 'react';
import Logo from '../../assets/svg/logo_circle.svg';

import './Loading.scss';

const Loading =() =>{
    return(
        <div className='flex-center full-screen'>
            <img src={Logo} width='400px' alt='loading'/>
        </div>
    )
}

export const LoadingCircleDiv =(props) =>{
    if(props.center){
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div className='loading-circle' style={{transform: props.scale? `scale(${props.scale})`:null}}>
                    <div className='inner-circle'></div>
                    <div className='rotary-circle rotate'></div>
                </div>
            </div>
        )
    }else{
        return(
            <div style={{position:'absolute', left:props.left? props.left:'25px'}}>
                <div className='loading-circle'>
                    <div className='inner-circle'></div>
                    <div className='rotary-circle rotate'></div>
                </div>
            </div>
        )
    }
}

export default Loading;