import { Helmet } from "react-helmet";
import {ReactComponent as TrustBadge} from '../../assets/svg/trust-badge.svg';
import {ReactComponent as Secure} from '../../assets/svg/secure.svg';

// import './Payments.scss';
import { AuthenticBadge } from "../../components/badges/AuthenticBadge";
import { WasThisHelpful } from "../../components/survey/SurveyQuestions";
import { webTitle } from "../Constants";

export const CraftedProducts = () =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <Helmet>
                <title>Quality Checks/ Crafted Products - {webTitle}</title>
            </Helmet>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <section>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Crafted Products</h1>
                        <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            {/* Date updated Privacy Policy */}
                            <span style={{fontWeight:'600'}}>28 October, 2023</span>
                        </span>
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <AuthenticBadge />
                    </div>

                    <div style={{height:'50px'}}></div>
                    <p style={{fontWeight:'600', fontStyle:'italic'}}>This badge represents authenticity of the material used to manufacture the product.</p>
                    <p>If you see this badge on a product description page, you can be assured that the product has undergone a quality check and is crafted from whatever the badge says. This badge is an indication of the quality of the product and the materials used in its manufacturing. It is a testament to the manufacturer's commitment to providing high-quality products that meet customer expectations. If you have any further questions or concerns, please do not hesitate to contact us.</p>
                    <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
                </section>
            </div>
        </div>
    )
}