import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { title, webTitle, website, jurisdiction, onlineStoreWebsite, blogWebsite } from "./Constants";
import { WasThisHelpful } from "../components/survey/SurveyQuestions";

const TermsOfUse =() =>{
    window.scrollTo({ top: 0, behavior:'smooth'});
    // useEffect(()=>{
    //     window.scrollTo({top:0, behavior:'smooth'})
    // })
    return(
        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px',textAlign:'justify', fontSize:'0.9rem'}}>
                <Helmet>
                    <title>Terms of use - {webTitle}</title>
                </Helmet>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <h1>Terms Of Use</h1>
                    <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                        {/* Date updated Privacy Policy */}
                        <span style={{fontWeight:'600'}}>21 May, 2024</span>
                    </span>
                </div>
                
                <div style={{height:'50px'}}></div>

                <p style={{fontWeight:'600'}}>
                    This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                </p>
                <p style={{fontWeight:'500'}}>
                    This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name [{website}, {onlineStoreWebsite}, {blogWebsite}] (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”)
                </p>
                <p>
                    Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with {title} and these terms and conditions including the policies constitute Your binding obligations, with {webTitle}.
                </p>
                <p>
                    For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing Registration Data while registering on the Platform as Registered User using any mobile device/computer system. {webTitle} allows the User to surf the Platform but registration on the Platform is mandatory to complete the purchase. The term "We", "Us", "Our" shall mean {title}.
                </p>
                <p>
                    When You use any of the services provided by Us through the Platform, including but not limited to, (e.g. Product Reviews), You will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the Platform following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform.
                </p>
                <p style={{fontWeight:'600'}}>
                    ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. <span style={{fontWeight:'400'}}>By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by {webTitle} Policies (including but not limited to Privacy Policy available at Privacy) as amended from time to time.</span>
                </p>

                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Membership Eligibility</p>
                <p>Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e. under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.</p>
                <p>As a minor if you wish to transact on the Platform, such transaction on the Platform may be made by your legal guardian or parents. {webTitle} reserves the right to terminate your membership and / or refuse to provide you with access to the Platform if it is brought to {webTitle} notice or if it is discovered that You are under the age of 18 years and transacting on the Platform.</p>

                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Your Account and Registration Obligations</p>
                <p>If You use the Platform, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide You with access to the Platform.</p>
                <p>Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Platform. It is your responsibility to ensure that Your mobile phone number and your email address is up to date on the Platform at all times. You agree to notify Us promptly if your mobile phone number or e-mail address changes by updating the same on the Platform through a onetime password verification.</p>
                <p>You agree that {webTitle} shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your Account in cases, including, where You have failed to update Your revised mobile phone number and/or e-mail address on the Platform.</p>
                <p>If You share or allow others to have access to Your account on the Platform, You shall be solely liable and responsible for all the activities undertaken under Your Account, and any consequences therefrom.</p>
            
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Communications</p>
                <p>When You use the Platform or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>

                <div style={{height:'20px'}}></div>
                <p>{webTitle} is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>
            
                <p style={{fontWeight:'600'}}>Disclaimer: Pricing on any product(s) as is reflected on the Platform may be due to some technical issue or typographical error incorrectly reflected and in such an event {title} may cancel your order(s).</p>
                
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Charges</p>
                <p>Membership on the Platform is free for buyers. {webTitle} does not charge any fee for browsing and buying on the Platform. {webTitle} reserves the right to change its Fee Policy from time to time. In particular, {webTitle} may at its sole discretion introduce new services and modify some or all of the existing services offered on the Platform. In such an event {webTitle} reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be. Changes to the Fee Policy shall be posted on the Platform and such changes shall automatically become effective immediately after they are posted on the Platform. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance of all applicable laws including those in India for making payments to {title}.</p>
            
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Use of the Platform</p>
                <p>You agree, undertake and confirm that Your use of Platform shall be strictly governed by the following binding principles:</p>
                <ul style={{display:'flex', flexDirection:'column'}}>
                    <li>You shall not host, display, upload, modify, publish, transmit, update or share any information which:</li>
                    <span>(a) belongs to another person and to which You does not have any right to;</span>
                    <span>(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</span> 
                    <span>(c) is misleading in any way;</span>
                    <span>(d) is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</span>
                    <span>(e) harasses or advocates harassment of another person;</span>
                    <span>(f) involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</span>
                    <span>(g) promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</span>
                    <span>(h) infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</span>
                    <span>(i) promotes an illegal or unauthorized copy of another person's copyrighted work (see "Copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</span>
                    <span>(j) contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</span>
                    <span>(k) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</span>
                    <span>(l) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</span>
                    <span>(m) contains video, photographs, or images of another person (with a minor or an adult);</span>
                    <span>(n) tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</span>
                    <span>(o) engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Platform. Throughout these Terms of Use, {webTitle} prior written consent means a communication coming from {webTitle} Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</span>
                    <span>(p) solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</span>
                    <span>(q) interferes with another USER's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services;</span>
                    <span>(r) refers to any Platform or URL that, in Our sole discretion, contains material that is inappropriate for the Platform or any other Platform, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.</span>
                    <span>(s) harm minors in any way;</span>
                    <span>(t) infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</span>
                    <span>(u) violates any law for the time being in force;</span>      
                    <span>(v) deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</span>
                    <span>(w) impersonate another person;</span>
                    <span>(x) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</span>
                    <span>(y) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation;</span>
                    <span>(z) is false or inaccurate;</span>
                    <span>(aa) shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</span>
                    <span>(ab) shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs");</span>   

                    <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve Our right to bar any such activity.</li>
                    <li>You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.</li>
                    <li>You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Platform, or any other customer, including any account on the Platform not owned by You, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Platform.</li>
                    <li>You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or {webTitle} systems or networks, or any systems or networks connected to {webTitle}.</li>
                    <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.</li>
                    <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal You send to Us on or through the Platform or any service offered on or through the Platform. You may not pretend that You are, or that You represent, someone else, or impersonate any other individual or entity.</li>
                    <li>You may not use the Platform or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity.</li>
                    <li>Solely to enable Us to use the information You supply Us with, so that we are not violating any rights You might have in Your Information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to Your Information. We will only use Your information in accordance with the Terms of Use and Privacy Policy applicable to use of the Platform.</li>        
                </ul>

                <div style={{height:'20px'}}></div>
                <p>We reserve the right, but has no obligation, to monitor the materials posted on the Platform. {webTitle} shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect {webTitle} views. In no event shall {webTitle} assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that You have all necessary rights in and to all Content which You provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.</p>
                <ul>
                    <li>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between You and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.</li>
                    <li>It is possible that other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Platform and that You may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about You due to your use of the Platform, and that the recipient may use such information to harass or injure You. We does not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that We are not responsible for the use of any personal information that You publicly disclose or share with others on the Platform. Please carefully select the type of information that You publicly disclose or share with others on the Platform.</li>
                    <li>{webTitle} shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).</li>
                </ul>

                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Contents Posted on Platform</p>
                <p>Except as expressly provided in these Terms of Use, no part of the Platform and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, Platform or other medium for publication or distribution or for any commercial enterprise, without {webTitle} express prior written consent.</p>
                <p>You may use information on the products and services purposely made available on the Platform for downloading, provided that You (1) do not remove any proprietary notice language in all copies of such documents, (2) use such information only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media, (3) make no modifications to any such information, and (4) do not make any additional representations or warranties relating to such documents.</p>
                <p>You shall be responsible for any notes, messages, emails, reviews, ratings, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the Platform (collectively, "Content"). Such Content will become Our property and You grant Us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with Our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include the Content You provide. You agree that any Content You post may be used by us, consistent with Our Privacy Policy and Rules of Conduct on Site as mentioned herein, and You are not entitled to any payment or other compensation for such use.</p>
                <p>SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THE PLATFORM.</p>

                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Indemnity</p>
                <p>You shall indemnify and hold harmless {webTitle}, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</p>
            
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Applicable Law</p>
                <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in {jurisdiction}.</p>
            
            
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Jurisdictional Issues/Sale in India Only</p>
                <p>Unless otherwise specified, the material on the Platform is presented solely for the purpose of sale in India. {webTitle} make no representation that materials in the Platform are appropriate or available for use in other locations/Countries other than India. Those who choose to access this site from other locations/Countries other than India do so on their own initiative and {webTitle} is not responsible for supply of products/refund for the products ordered from other locations/Countries other than India, compliance with local laws, if and to the extent local laws are applicable.</p>
            
            
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Replacement</p>
                <p>Replacement is the action or process of replacing something in place of another. A Buyer can request for replacement whenever he is not happy with the item, reason being Damaged in shipping, Defective item, Item(s) missing, wrong item shipped and the like. Buyer needs to raise the replacement request within the return period applicable to the respective product. Buyer is required to raise a replacement request on the Platform (My orders).</p>
                <p>In case {webTitle}(herein after "We") don't have the product at all, We can provide the refund to the Buyer and Buyer shall be obligated to accept the refund in lieu of replacement.</p>
                
                
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Product Description</p>
                <p>We do not warrant that Product description or other content of this Platform is accurate, complete, reliable, current, or error-free and assumes no liability in this regard.</p>
            
                <div style={{height:'20px'}}></div>
                <p style={{fontWeight:'600'}}>Limitation of Liability</p>
                <p>IN NO EVENT SHALL <span style={{fontWeight:'600'}}>{webTitle.toUpperCase()}</span> BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                <p style={{fontWeight:'600', fontStyle:'italic'}}>We reserve the right to amend these terms of use at any time without prior notice.</p> 

                <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
            </div>
        </div>
    )
}

export default TermsOfUse;