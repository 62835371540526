import { Helmet } from "react-helmet";
import { shopHostname, webTitle } from "./Constants";
import { Link } from "../components/extraFunctions/Link";

const About =() =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <Helmet>
                <title>About us- {webTitle}</title>
            </Helmet>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <section>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>About us</h1>
                        {/* <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            <span style={{fontWeight:'600'}}>28 October, 2023</span>
                        </span> */}
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        {/* <AuthenticBadge /> */}
                    </div>

                    {/* <div style={{height:'50px'}}></div> */}
                    {/* <p style={{fontWeight:'600', fontStyle:'italic'}}>This badge represents authenticity of the material used to manufacture the product.</p> */}
                    
                    <p>
                        We are a business that specializes in <strong>trading natural stones</strong> for various purposes. We have been in this industry for over <strong>20 years</strong> and have established a reputation for <strong><em>quality and reliability</em></strong>. We source our stones from different regions of the world and offer a wide range of products.
                    </p>
                    <p>
                        <strong>We are now expanding our business</strong> to cater to the growing demand for <strong>products crafted from natural stones</strong>. These products include jewelry, accessories, home decor, and gifts. We have a <strong>team of skilled artisans</strong> who create unique and beautiful designs using natural stones of different colors, shapes, and textures. <strong>We sell our products online</strong> through our website and also through our <strong>retail outlets</strong> across the country.
                    </p>
                    <br/>
                    <p>
                        We are <strong>passionate about natural stones</strong> and their potential to enhance the beauty and value of any space or occasion.
                    </p>
                    <br/>
                    <div style={{background:'#c6e7cc', padding:'20px 50px'}}>
                        <p style={{fontSize:'1.5rem', fontWeight:'600', color:'#'}}>
                            <Link to='collections' hostname={shopHostname}>
                                <span>Explore</span>
                            </Link>
                            &nbsp;our collection of products and discover the charm and elegance of natural stones.
                        </p>
                    </div>
                    
                    {/* <WasThisHelpful page={window.location.pathname} submitResponse={true}/> */}
                </section>
            </div>
        </div>
    )
}

export default About;