import { useState } from "react";
import { SurveyDislikeButton, SurveyLikeButton } from "../button/SurveyButtons"
import './Survey.scss';
import { BarLoader } from "react-spinners";

export const WasThisHelpful =({page, submitResponseVisible}) =>{
    const [surveySubmitted, setSurveySubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tellUsMore, setTellUsMore] = useState(false);
    const [feedback, setFeedback] = useState('');
    const handleClick=(value)=>{
        if(value){
            // Then the page was liked
            setLoading(true);
            // console.log('page: ', page, '- liked');
            setTimeout(()=>{
                setLoading(false); 
                setSurveySubmitted(true);
            },3000)
        }else{
            // The page was disliked
            // console.log('page: ', page, '- disliked');
            setTellUsMore(true); 
        }
        
    }
    const submitDislike=()=>{
        setLoading(true);
        setTimeout(()=>{
            setLoading(false); 
            setSurveySubmitted(true);
        },3000)
    }
    return(
        <div className="survey-short">
            {
                surveySubmitted?
                    submitResponseVisible? <span>Feedback submitted! Thank you</span> : null
                :
                    <>
                        <span>Was this helpful?</span>
                        {
                            loading?
                                <BarLoader color='#007f00'/>
                            :
                                <div style={{display:'flex', justifyContent:'space-around', width:'120px'}}>
                                    <SurveyLikeButton handleClick={()=>handleClick(1)}/>
                                    <SurveyDislikeButton handleClick={()=>handleClick(0)}/>
                                </div>
                        }
                    </>                    
            }
            {
                tellUsMore && surveySubmitted===false && loading===false?
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', paddingTop:'10px'}}>
                        <span>Tell us more</span>
                        <textarea 
                            style={{borderRadius:'10px', border:'3px solid #eee', padding:'5px 10px'}}
                            value={feedback}
                            rows={3}
                            cols={15}
                            onChange={()=>setFeedback()}
                            placeholder='Help us improve'
                        />
                        <button className="light-button" style={{marginTop:'10px', fontSize:'0.8rem', fontWeight:'600'}} onClick={()=>submitDislike()}>Submit</button>
                    </div>
                : null
            }
        </div>
    )
}