import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {ReactComponent as SupportTeam} from '../assets/svg/support-team.svg';
import {ReactComponent as Email} from '../assets/svg/email.svg';
import { contactUsDiscordBotURL, hrefSupportContact, hrefSupportEmail, supportTeamContact, supportTeamEmail, webTitle } from "./Constants";
// import { Link } from '../components/extraFunctions/Link';

import './general.scss';
import { Button } from '../components/button/Button';

const Contact=() =>{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    // const [iam, setIam] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior:'smooth'});
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        // setIam('');
    }, []);

    const submitForm =async()=>{
        if(name.length>=3 && email.length && phone.length && message.length){
            if(phone){
                // Set submitting to true ---- it will show loading screen
                setSubmitting(true);
                const notified = await fetch(contactUsDiscordBotURL, {
                    method: 'POST',
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({
                        content: 'Someone tried to contact us, get back to them.',
                        embeds: [
                            {
                                title: 'Contact Form Submitted',
                                type: 'rich',
                                fields: [
                                    {name:'Name', value: name},
                                    {name:'Phone', value:phone},
                                    {name:'Email', value: email.toLowerCase()},
                                    {name:'Message', value:message}
                                ]
                            }
                        ]
                    })
                })
                console.log("\n\nNotified: ", notified.ok);
                if(notified.ok){
                    // Form Successfully Submitted
                    setSubmitting(false);
                    setSubmitted(true);
                }else{
                    // Form Not Submitted
                    setSubmitting(false);
                    setSubmitted(false);
                }
            }
        }
    }

    return(
        <div style={{display:'flex', justifyContent:'center'}}>
            <Helmet>
                <title>Contact us- {webTitle}</title>
            </Helmet>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <section style={{width:'90%'}}>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Contact us</h1>
                    </div>
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <div style={{position:'relative', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'30px 15px'}}>
                            <div>
                                <p style={{fontSize:'1.3rem', textAlign:'center'}}>
                                    We are committed to ensuring customer satisfaction and continuously improving our services
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Form with a message field */}
                    <form 
                        className='flex-cc' 
                        style={{
                            width:'100%', 
                            marginTop:'50px', 
                            border:'2px solid #24422a',
                            borderTopRightRadius:'55px', 
                            padding:'50px 0px',
                        }}
                        onSubmit={(e)=>{e.preventDefault(); submitForm()}}    
                    >
                        {
                            submitting?
                                <div className='w-full' style={{maxWidth:'500px', padding:'0px 30px'}}>
                                    <p>
                                        <strong>Submitting your information. Contacting our team ...</strong>
                                    </p>
                                </div>
                            : 
                                (
                                    submitted?
                                        <div className='w-full' style={{maxWidth:'500px', padding:'0px 30px'}}>
                                            <p>
                                                <strong>Thank you, we have received your contact information. One of our team members will contact you shortly.</strong>
                                            </p>
                                        </div>
                                    :
                                        <div className='w-full' style={{maxWidth:'500px', padding:'0px 30px'}}>
                                            <p>
                                                <strong>Please fill in the form and we will get back to you ASAP</strong>
                                            </p>
                                        <br/><br/>
                                        <div className='flex-sbc flex-col mb-20'>
                                            {/* <label for='name'>Name</label> */}
                                            <input 
                                                className='w-full fs-11 pa-10 br-5 input-style'
                                                type="text"
                                                // name='name'
                                                value={name}
                                                onChange={(e)=>setName(e.target.value)}
                                                placeholder='Full Name'
                                                required
                                            />
                                        </div>
                                        <div className='flex-sbc flex-col mb-20'>
                                            {/* <label>Email</label> */}
                                            <input 
                                                className='w-full fs-11 pa-10 br-5 input-style'
                                                type="email"
                                                // name='email'
                                                value={email}
                                                onChange={(e)=>setEmail(e.target.value)}
                                                placeholder='Email Id'
                                                required
                                            />
                                        </div>
                                        <div className='flex-sbc flex-col mb-20'>
                                            {/* <label>Phone</label> */}
                                            <input 
                                                className='w-full fs-11 pa-10 br-5 input-style'
                                                type="tel"
                                                // name='phone'
                                                value={phone}
                                                onChange={(e)=>setPhone(e.target.value)}
                                                placeholder='Phone with country code'
                                            />
                                        </div>
                                        <div className='flex-sbc flex-col mb-20'>
                                            {/* <label>Message</label> */}
                                            <textarea
                                                className='w-full fs-11 pa-10 br-5 input-style'
                                                // name='message'
                                                value={message}
                                                onChange={(e)=>setMessage(e.target.value)}
                                                placeholder='Message for our Team'
                                                required
                                            />
                                        </div>

                                        <div className='flex-cc'>
                                            <Button type='submit' className='light-button'>Get in touch</Button>
                                        </div>

                                    </div>
                                )
                        }

                    </form>
                    <br/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Email us</h1>
                    </div>
                    {/* <div style={{height:'50px'}}></div> */}
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <div style={{position:'relative', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'30px 15px'}}>
                            <div style={{width:'100px', height:'100px'}}>
                                <Email />
                            </div>
                            <div style={{padding:'0px 5px', maxWidth:'600px'}}>
                                <p style={{fontSize:'1.3rem', textAlign:'center'}}>
                                    Get in touch with us
                                    <br/>
                                    <span style={{fontSize:'0.8rem', fontStyle:'italic'}}>We will get back to you within 48hrs</span>
                                </p>
                                <p style={{textAlign:'center'}}>
                                    <a href={hrefSupportEmail} style={{fontWeight:'600', fontSize:'1.3rem'}}>{supportTeamEmail}</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div style={{height:'50px'}}></div> */}
                    <br/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Talk to us</h1>
                        {/* <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            <span style={{fontWeight:'600'}}>28 October, 2023</span>
                        </span> */}
                    </div>
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <div style={{position:'relative', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'30px 15px'}}>
                            <div style={{width:'100px', height:'100px'}}>
                                <SupportTeam />
                            </div>
                            <div>
                                <p style={{fontSize:'1.3rem', textAlign:'center'}}>
                                    Talk to a representative
                                </p>
                                <div style={{padding:'0px 5px', maxWidth:'600px'}}>
                                    <p style={{textAlign:'justify'}}>
                                        <span style={{fontSize:'0.8rem', fontStyle:'italic'}}>Please note that we have specific timings for receiving calls. We will be happy to assist you during these hours. Thank you for your understanding</span>
                                    </p>
                                </div>
                                <p style={{textAlign:'center'}}>
                                    <a href={hrefSupportContact} style={{fontWeight:'600', fontSize:'1.3rem', textDecoration:'none'}}>{supportTeamContact}</a>
                                </p>
                                <div style={{color:'#888'}}>
                                    <p style={{textAlign:'center'}}><strong>Mon-Sat</strong> (<strong>9:00AM - 7:00PM</strong>) Indian Standard Time</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <WasThisHelpful page={window.location.pathname} submitResponse={true}/> */}
                </section>
            </div>
        </div>
    )
}

export default Contact;