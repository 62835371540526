import { Helmet } from "react-helmet";
// import {ReactComponent as TrustBadge} from '../../assets/svg/trust-badge.svg';
// import {ReactComponent as Secure} from '../../assets/svg/secure.svg';

import './Payments.scss';
// import { AuthenticBadge } from "../../components/badges/AuthenticBadge";
import { WasThisHelpful } from "../../components/survey/SurveyQuestions";
import { Link } from "../../components/extraFunctions/Link";
import { webTitle } from "../Constants";

export const RefundsCancellation = () =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <Helmet>
                    <title>Help/Refunds & Cancellation - SG Marbles</title>
                </Helmet>
                <section>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Refunds & Cancellation</h1>
                        <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            {/* Date updated Privacy Policy */}
                            <span style={{fontWeight:'600'}}>23 May, 2024</span>
                        </span>
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    
                    {/* <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <AuthenticBadge />
                    </div> */}
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <p style={{fontWeight:'600', fontStyle:'italic', color:'darkgreen', padding:'30px 50px'}}>Kindly be aware that the unique color and pattern variations found in  natural stones are inherent natural features. These variations should not be considered defects.</p>
                    </div>

                    <p style={{fontWeight:'600', fontStyle:'italic'}}>Refunds and cancellations are only applicable for purchases made & delivered in India</p>
                    <p style={{fontWeight:'600', fontStyle:'italic'}}>Do refer the respective product's applicable return/replacement terms on the product page for any exceptions. 
                        For all products, the returns/replacement terms provided on the product page shall prevail over the general return policy.
                    </p>
                    <p>At {webTitle}, we are committed to ensuring the satisfaction of our customers. Our refund/cancellation policy is designed to be transparent and customer-friendly, ensuring that the customers feel confident in their purchase and know that their satisfaction is our foremost concern.</p> 
                    <p>If you are not satisfied with your purchase due to a defect or discrepancy in the order, you could qualify for a refund or an exchange of the purchased item according to the return information provided on the respective product page. Please make sure that the products are unused and in their original packaging to be eligible for a refund.</p> 
                    <p>Custom orders are non-refundable due to the bespoke nature of the work. In the event of a return, transportation and restocking fees may apply. Should you return the product within the allotted time frame and it adheres to our <Link to='return-policy'>return policy</Link>, we will initiate a refund to your initial payment method. This will occur within 14 business days following our receipt and evaluation of the returned product.</p>
                    {/* <p>To cancel an order, contact our <Link to='contact'>support team</Link>. Refund process may take upto 7 business days. The refund amount may take upto 14 business days to reflect in your bank account from the date of order cancellation.</p> */}
                    <p>If you have any questions or need clarification, feel free to reach out to <Link to='contact'>us</Link>. Additionally, you can also contact us regarding cancellations.</p>
                    <p style={{fontWeight:'600', fontStyle:'italic'}}>We reserve the right to amend this policy at any time without prior notice.</p> 
                    {/* <p>For any queries or clarifications, please <Link to='contact'>contact us</Link>. You can also contact us for cancellation.</p> */}
                    <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
                </section>
            </div>
        </div>
    )
}