import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { useEffect, useState } from 'react';
import { ComingSoon } from './pages/ComingSoon';
import { UrlMatcher } from './components/extraFunctions/UrlMatcher';
import Route from './components/Router/Route';
import ReturnPolicy from './pages/ReturnPolicy';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { Page404 } from './pages/Page404';

function App() {
	// const [page, setPage] = useState(null);
	const [path, setPath] = useState('/');
	useEffect(()=>{
		// Use UrlMatcher to match with the best Url PathName and the repective page
		// const result = UrlMatcher(window.location.pathname);
		setPath(window.location.pathname);
	}, [window.location.pathname])
	return (
		<div className="App">
			<Header />
			<div style={{marginTop:'100px'}}>
				{/* Main Page Content **varies based on URL Matcher Result */}
				<UrlMatcher path={path}/>
				{/* <Route path='/' component={ComingSoon} /> */}
				
				{/* Policies */}
				{/* <Route path='/return-policy' component={ReturnPolicy} />
				<Route path='/privacy-policy' component={PrivacyPolicy} />
				<Route path='/terms-of-use' component={TermsOfUse} />
				<Route path='/security' component={Page404} /> */}
				
				{/* About */}
				{/* <Route path='/about-us' component={Page404} />
				<Route path='/contact-us' component={Page404} /> */}
				
				{/* Help */}
				{/* <Route path='/help/payments' component={Page404} />
				<Route path='/help/refunds-cancellation' component={Page404} />
				<Route path='/help/faq' component={Page404} />
				 */}
				{/* App routes */}
				{/* <Route path='/stone' component={Page404} />
				<Route path='/stone/:id' component={Page404} />
				<Route path='/stone/:id/request-quote' component={Page404} />
				<Route path='/gallery' component={Page404} /> */}
			</div>	
			
			<Footer />
		</div>
	);
}

export default App;
